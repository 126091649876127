exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-categories-animals-js": () => import("./../../../src/pages/categories/animals.js" /* webpackChunkName: "component---src-pages-categories-animals-js" */),
  "component---src-pages-categories-famous-faces-js": () => import("./../../../src/pages/categories/famous-faces.js" /* webpackChunkName: "component---src-pages-categories-famous-faces-js" */),
  "component---src-pages-categories-food-and-drink-js": () => import("./../../../src/pages/categories/food-and-drink.js" /* webpackChunkName: "component---src-pages-categories-food-and-drink-js" */),
  "component---src-pages-categories-japanese-js": () => import("./../../../src/pages/categories/japanese.js" /* webpackChunkName: "component---src-pages-categories-japanese-js" */),
  "component---src-pages-categories-js": () => import("./../../../src/pages/categories.js" /* webpackChunkName: "component---src-pages-categories-js" */),
  "component---src-pages-categories-music-js": () => import("./../../../src/pages/categories/music.js" /* webpackChunkName: "component---src-pages-categories-music-js" */),
  "component---src-pages-categories-nature-js": () => import("./../../../src/pages/categories/nature.js" /* webpackChunkName: "component---src-pages-categories-nature-js" */),
  "component---src-pages-categories-nautical-js": () => import("./../../../src/pages/categories/nautical.js" /* webpackChunkName: "component---src-pages-categories-nautical-js" */),
  "component---src-pages-categories-patterns-js": () => import("./../../../src/pages/categories/patterns.js" /* webpackChunkName: "component---src-pages-categories-patterns-js" */),
  "component---src-pages-categories-retro-js": () => import("./../../../src/pages/categories/retro.js" /* webpackChunkName: "component---src-pages-categories-retro-js" */),
  "component---src-pages-categories-surreal-js": () => import("./../../../src/pages/categories/surreal.js" /* webpackChunkName: "component---src-pages-categories-surreal-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-returns-and-refunds-js": () => import("./../../../src/pages/returns-and-refunds.js" /* webpackChunkName: "component---src-pages-returns-and-refunds-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-shipping-and-payment-js": () => import("./../../../src/pages/shipping-and-payment.js" /* webpackChunkName: "component---src-pages-shipping-and-payment-js" */),
  "component---src-templates-product-page-index-js": () => import("./../../../src/templates/ProductPage/index.js" /* webpackChunkName: "component---src-templates-product-page-index-js" */)
}

